.container {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1250px) {
    width: 80%;
  }
}

label {
  font-weight: bold;
}

.form {
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 780px) {
    flex-direction: column;
  }
}

.field {
  display: flex;
  flex-direction: column;
  width: 30%; /* Change width to accommodate 3 inputs per row */
  margin-bottom: 20px;
  @media (max-width: 780px) {
    width: 100%; /* Stacks inputs on small screens */
  }
}

/* Keep the text area on a new row */
.fieldTextArea {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.inputName {
  width: 100%;
  margin-bottom: 15px;
}

.input {
  width: 100%;
  height: 45px;
  font-size: 20px;
  padding-left: 20px;
  background-color: #fff;
}

.textArea {
  padding-top: 20px;
  font-size: 15px;
  padding-left: 20px;
  height: 200px;
}

input,
select,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  border-radius: 20px;
}

textarea {
  height: 100px; /* Adjust height as needed */
  border-radius: 20px;
}

.submitBtn {
  width: 280px;
  margin-right: auto;
  margin-left: auto;
  padding: 10px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 45px;
  font-size: 20px;
  transition: background-color 0.3s ease;
  border-radius: 20px;
  font-weight: 600;
}
