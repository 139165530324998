.container {
  margin-top: 100px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 850px) {
    flex-direction: column;
  }
}

.title {
  font-size: 50px;
  color: #000000;
  text-align: center;
  max-width: 60%;
  font-weight: bold;
  @media (max-width: 1135px) {
    font-size: 40px;
    max-width: 80%;
  }

  @media (max-width: 950px) {
    font-size: 40px;
    max-width: 100%;
  }
}
.seeButton {
  color: rgb(255, 255, 255);
  background-color: #000000;
  height: 50px;
  width: 280px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 10px;
  border-radius: 40px;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
  margin-left: 50px;
  @media (max-width: 850px) {
    margin-left: 0;
  }
}
