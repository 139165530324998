.container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  min-height: 75vh;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1400px) {
    align-items: center;
    flex-direction: column;
  }
}

.title {
  font-size: 60px;
  color: #000000;
  text-align: left;
  max-width: 45%;
  font-weight: bold;
  @media (max-width: 1750px) {
    font-size: 50px;
  }
  @media (max-width: 1750px) {
    margin-top: 120px;
    // font-size: 40px;
    max-width: 35%;
    text-align: center;
  }
  @media (max-width: 1400px) {
    max-width: 100%;
  }
}

.description {
  font-size: 27px;
  font-weight: 600;
  color: #535353;
  text-align: left;
  max-width: 50%;
  @media (max-width: 1750px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: 1750px) {
    // margin-top: 120px;
    // font-size: 40px;
    max-width: 60%;
    text-align: center;
  }
  @media (max-width: 1400px) {
    max-width: 100%;
  }
}
