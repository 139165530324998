.container {
  width: 100%;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  background-color: #000000;
}

.midContainer {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.titleBox {
  display: flex;
  @media (max-width: 915px) {
    flex-direction: column;
  }
}

.dividerBox {
  justify-content: left;
  display: flex;
  @media (max-width: 820px) {
    flex-direction: column;
  }
}

.title {
  font-size: 60px;
  color: #ffffff;
}

.subTitle {
  font-size: 30px;
  margin: 0 0 20px 0;
  color: #ffffff;
  text-wrap: nowrap;
}

.collumn {
  // margin-right: 150px;
  margin-top: 100px;
  margin-right: 150px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1050px) {
    margin-right: 100px;
  }
  @media (max-width: 960px) {
    margin-right: 50px;
  }
}

.link {
  color: #c9c9c9;
  text-decoration: none;
  font-size: large;
  margin-bottom: 15px;
  text-wrap: wrap;
}

.divider {
  width: 80%;
  height: 1px;
  background-color: #727272;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.rightT {
  color: #585858;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0;
  padding-bottom: 50px;
}

.logoImgBox {
  width: 100%;
  max-width: 400px;
  height: 200px;

  // background-color: #bc2e2e;
  display: flex;
  margin-top: 45px;
  @media (max-width: 500px) {
    width: 100%;
    height: 160px;
  }
}

.logoTitle {
  font-size: 50px;
  color: #ffffff;
  text-align: left;
  max-width: 60%;
  font-weight: 550;
  margin-top: 70px;
  margin-left: 100px;
  @media (max-width: 915px) {
    max-width: 100%;
    margin-left: 0px;
    font-size: 45px;
    margin-top: 30px;
  }
}

.logoImg {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-color: #fff;
  background-image: url('../../../static//logod3.png');
}
