.container {
  background-image: url('../../../static/welcImg3.png');
  background-size: 100%; /* Reduce the size of the background image to 90% of the container's width */
  background-position: center; /* Keep the image centered */
  background-repeat: no-repeat; /* Ensure the image doesn't repeat */
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1200px) {
    background-image: url('');
    background-color: #fff;
  }
}

.midContainer {
  // height: 100%;
  width: 80%;
  // background-color: #a16969;
  // background-color: red;/* Adjust top positioning */
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50;
  left: 30;
}

.title {
  color: #00ff1e;
  margin-bottom: 0;
}

.description {
  font-size: 17px;
  font-weight: 600;
  color: #535353;
  text-align: left;
  max-width: 80%;
  line-height: 27px;
  @media (max-width: 950px) {
    font-size: 16px;
    max-width: 100%;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  @media (max-width: 1450px) {
    max-width: 50%;
  }
  @media (max-width: 1200px) {
    max-width: 100%;
  }
}

.link {
  border-radius: 15px;
  background-color: #000;
  color: #fff;
  width: 180px;
  font-size: 1.3em;
  text-decoration: none;
  font-weight: 600;
  height: 60px;
  text-align: center;
  line-height: 60px; /* Adjust to match the height of the link */
}

a.scrollBtn {
  position: absolute;
  height: 3.5em;
  width: 2.4em;
  border: 0.15em solid #000000;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 2.5em;
  border-radius: 3em;
  transition: opacity 0.5s ease-in-out;
}
a.scrollBtn:before {
  position: absolute;
  content: '';
  margin: auto;
  left: 0;
  right: 0;
  top: 0.2em;
  height: 1em;
  width: 1em;
  background-color: #000000;
  border-radius: 50%;
  animation: move-down 1.5s infinite;
}
@keyframes move-down {
  100% {
    transform: translateY(1.7em);
  }
}
a.scrollBtn:after {
  position: absolute;
  content: 'SCROLL DOWN';
  width: 11em;
  display: block;
  text-align: center;
  left: -4.2em;
  bottom: -2em;
  font-size: 1em;
  color: #ffffff;
  letter-spacing: 3px;
  font-weight: 600;
}
@media screen and (max-width: 500px) {
  a.scrollBtn {
    font-size: 12px;
  }
}

.title {
  font-size: 60px;
  color: #000000;
  text-align: left;
  font-weight: bold;
  @media (max-width: 1135px) {
    font-size: 50px;
  }
}

.seeButton {
  color: rgb(255, 255, 255);
  background-color: #000000;
  height: 45px;
  width: 160px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 13px;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 500;

  @media (max-width: 768px) {
    margin-left: 0;
  }
  @media (max-width: 550px) {
    height: 45px;
    width: 130px;
    padding-top: 13px;
  }
}

.contactButton {
  color: rgb(0, 0, 0);
  height: 45px;
  width: 100px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 13px;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 500;
  margin-left: 45px;

  &:hover {
    margin-left: 55px;
  }
  @media (max-width: 550px) {
    margin-left: 0px;
    width: 130px;
  }
}

.boxDivider {
  display: flex;
  margin-top: 20px;
}

.imgBox {
  width: 100%; /* Adjust according to your design */
  height: 700px; /* Adjust according to your design */
  background-image: url('');
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
  border-radius: 30px;
  @media (max-width: 1440px) {
    display: none;
  }
}

@media (max-width: 1440px) {
  .midContainer {
    left: auto;
    right: auto;
    top: auto;
  }
}
