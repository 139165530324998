.container {
  margin-top: 100px;
  margin-bottom: 150px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0b0b0b;
}

.title {
  font-size: 45px;
  color: #ffffff;
  text-align: center;
  max-width: 60%;
  font-weight: bold;
  @media (max-width: 1135px) {
    font-size: 40px;
    max-width: 80%;
  }

  @media (max-width: 950px) {
    font-size: 30px;
    max-width: 80%;
  }
}

.description {
  font-size: 17px;
  font-weight: 600;
  color: #dddddd;
  text-align: center;
  max-width: 60%;
  line-height: 27px;
  @media (max-width: 950px) {
    font-size: 16px;
    max-width: 80%;
  }
}

.divider {
  margin-top: 30px;
  width: 100%;
  height: 0.5px;
  background-color: #898989;
}

.seeButton {
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  height: 40px;
  width: 200px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 10px;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 500;
  margin-top: 20px;
}
