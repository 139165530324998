.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  // padding: 20px;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 150px;

  @media (max-width: 1250px) {
    flex-direction: column-reverse;
    align-items: center;
  }

  &.reversed {
    flex-direction: row-reverse;

    @media (max-width: 1250px) {
      flex-direction: column-reverse;
    }
  }

  div {
    flex: 1;
    padding: 10px;
  }
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;

  @media (max-width: 1250px) {
    font-size: 55px;
    text-align: center;
  }
  @media (max-width: 950px) {
    font-size: 40px;
    max-width: 100%;
  }
}

.description {
  margin-top: 30px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #535353;

  @media (max-width: 1250px) {
    text-align: center;
    font-size: 25px;
    width: 100%;
  }
  @media (max-width: 950px) {
    font-size: 19px;
    max-width: 100%;
  }
}

.textContainer {
  max-width: 50%;
  @media (max-width: 1250px) {
    max-width: 100%;
  }
}

img {
  width: 450px;
  height: 450px;
  object-fit: cover;
  border-radius: 30px;
  // flex: 1;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }
}
