.header {
  width: 100%;
  height: 60px;
  position: absolute;
  &.MuiPaper-elevation4 {
    box-shadow: none !important;
  }
}

.appBar {
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
}

.toolbar {
  background-color: #ffffff;
  box-shadow: none;
}

.incontainer {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

header {
  background-color: #ffffff;
  box-shadow: none !important;
  &.MuiPaper-elevation4 {
    box-shadow: none !important;
  }
}

.divider {
  width: 100%;
  padding-top: 10px 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: 900;
  color: #000000;
}

.divcontainerl {
  display: flex;
  align-items: center;
}

.menu {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 55px;
  left: 0;
  z-index: 999999;
}

.mobcontainer {
  margin-top: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logocontainer {
  height: 48px;
  width: 48px;
  margin-right: 50px;
}
.logo {
  background-image: url('../../../static/logoh2.png');
  background-size: cover;
  background-color: #ffffff;
  height: 100%;
}

.divcontainerl {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  background-color: #fff;
  border: none;
  margin-top: 20px;
  color: #000000;
  text-decoration: none;
  font-size: 32px;
  font-weight: normal;
}
