.container {
  width: 50%;
  margin: 0 auto;

  @media (max-width: 1250px) {
    width: 80%;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.row {
  display: flex;
  gap: 20px;

  @media (max-width: 780px) {
    flex-direction: column;
  }
}

.field {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.inputName {
  margin-bottom: 8px;
  font-weight: bold;
}

.input {
  height: 45px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  border-radius: 20px;
}

.hiddenInput {
  display: none;
}

.uploadBtn {
  width: 280px;
  margin-right: auto;
  margin-left: auto;
  padding: 10px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 45px;
  font-size: 20px;
  transition: background-color 0.3s ease;
  border-radius: 20px;
  font-weight: 600;

}

.submitBtn {
  width: 280px;
  margin-right: auto;
  margin-left: auto;
  padding: 10px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 45px;
  font-size: 20px;
  transition: background-color 0.3s ease;
  border-radius: 20px;
  font-weight: 600;
}

.fileName {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
  font-style: italic;
}
