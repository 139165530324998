.pricingSection {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  margin-top: 150px;

  @media (max-width: 1340px) {
    width: 80%;
  }

  @media (max-width: 850px) {
    width: 90%;
  }
}

.pricingTitle {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 10px;

  @media (max-width: 1250px) {
    font-size: 55px;
    text-align: center;
  }
  @media (max-width: 950px) {
    font-size: 40px;
    max-width: 100%;
  }
}

.pricingTable {
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pricingRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 0px 0 0 10px;
}

.altBackground {
  background-color: #f9f9f9;
}

.header {
  background-color: #fff;
  font-weight: bold;
  border: none;
}

.pricingItem {
  flex: 1;
  font-weight: 600;
  text-align: left;
  font-size: 1rem;

  &:nth-child(4) {
    text-align: right;
  }

  @media (max-width: 850px) {
    margin-top: 10px;
    flex: none;
    margin-bottom: 10px;
    text-align: left;
    text-wrap: wrap;
    max-width: 60%;
  }
}

.price {
  flex: 1;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;

  @media (max-width: 850px) {
    // width: 10%;
    flex: none;
    // margin-bottom: 10px;
    text-align: right;
    margin-right: 10px;
  }
}

.appointmentBtn {
  background-color: #000;
  color: #ffffff;
  font-weight: 600;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none; /* Ensure the link looks like a button */
  display: inline-block;

  @media (max-width: 850px) {
    width: 100%;
    text-align: center;
  }
}

/* Show the button in each row only for screens larger than 850px */
.showButtonOnLargeScreens {
  display: none;

  @media (min-width: 851px) {
    display: flex;
    width: 33.3%;
    justify-content: end;
  }
}

/* Show the large button below the table on small screens */
.bigAppointmentButton {
  display: none;
  margin-top: 20px;

  @media (max-width: 850px) {
    display: block;
    max-width: 280px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
  }
}

.optionaldiv {
  width: 280px;
  @media (max-width: 850px) {
    display: none;
  }
}
.pricingImage {
  width: 100%;
  height: 450px;
  object-fit: cover;
  border-radius: 30px;
  // flex: 1;

  @media (max-width: 768px) {
    width: 85%;
    margin-top: 20px;
  }
}
