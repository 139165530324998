.container {
  margin-top: 100px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 50px;
  color: #000000;
  text-align: center;
  max-width: 60%;
  font-weight: bold;
  @media (max-width: 1135px) {
    font-size: bold;
    max-width: 80%;
  }

  @media (max-width: 950px) {
    font-size: 40px;
    max-width: 100%;
  }
}

.description {
  font-size: 22px;
  font-weight: 600;
  color: #535353;
  text-align: center;
  max-width: 80%;
  @media (max-width: 950px) {
    font-size: 19px;
    max-width: 100%;
  }
}


.divider {
  margin-top: 30px;
  width: 100%;
  height: 0.5px;
  background-color: #898989;
}
