.container {
  margin-top: 100px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 850px) {
    flex-direction: column;
    width: 85%;
  }
}

.title {
  font-size: 50px;
  color: #000000;
  text-align: center;
  max-width: 60%;
  font-weight: bold;
  @media (max-width: 1135px) {
    font-size: 40px;
    max-width: 80%;
  }
  @media (max-width: 950px) {
    font-size: 40px;
    max-width: 100%;
  }
}

.item {
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  cursor: pointer;
}

.rowTitle {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #000000;
}

.arrow {
  font-size: 14px;
  color: #000000;
}

.description {
  overflow: hidden;
  transition:
    max-height 0.3s ease,
    opacity 0.3s ease;
  opacity: 0;
  max-height: 0;

  &.open {
    opacity: 1;
    max-height: 1000px; // Adjust to a reasonable max height for smooth animation
  }

  margin-left: 10px;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  color: #535353;
  text-align: left;

  @media (max-width: 1250px) {
    text-align: center;
    font-size: 25px;
    width: 100%;
  }
  @media (max-width: 950px) {
    font-size: 19px;
    max-width: 100%;
  }
}
