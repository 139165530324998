.header {
  width: 100%;
  height: 60px;
  // background-color: rgb(255, 255, 255);
  position: absolute;
  &.MuiPaper-elevation4 {
    box-shadow: none !important;
  }
}

.appBar {
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
}

.toolbar {
  background-color: #ffffff;
  box-shadow: none;
}

.incontainer {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

header {
  box-shadow: none !important;
  &.MuiPaper-elevation4 {
    box-shadow: none !important;
  }
}

.divider {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin-right: 80px !important;
  font-weight: 900;
  color: #000000;
}

.divcontainerl {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  background-color: #fff;
  border: none;
  font-size: large;
}

.txt {
  color: #000000;
}
.logocontainer {
  height: 50px;
  width: 50px;
  margin-right: 50px;
}
.logo {
  background-image: url('../../../static/logoh2.png');
  background-size: cover;
  background-color: #ffffff;
  height: 100%;
}
